import baseMessages from "./../common/base/baseMessages";
export default Object.freeze({
  ...baseMessages,
  APPROVAL_TYPE_IS_REQUIRED: "APPROVAL_TYPE_IS_REQUIRED",
  SET_ACCIDENT_DATE: "SET_ACCIDENT_DATE",
  APPROVAL_IS_NOT_PENDING: "APPROVAL_IS_NOT_PENDING",
  DIAGNOSIS_IS_ALREADY_ADDED: "DIAGNOSIS_IS_ALREADY_ADDED",
  INSURED_NOT_COVERED_FOR_THIS_DIAGNOSIS:
    "INSURED_NOT_COVERED_FOR_THIS_DIAGNOSIS",
  SURE_TO_DELETE_FOLLOW_UP_APPROVAL: "SURE_TO_DELETE_FOLLOW_UP_APPROVAL",
  SURE_TO_DELETE_THIS_APPROVAL_REQUEST_CLAIM_TYPE:
    "SURE_TO_DELETE_THIS_APPROVAL_REQUEST_CLAIM_TYPE",
  SURE_TO_DELETE_THIS_APPROVAL_REQUEST: "SURE_TO_DELETE_THIS_APPROVAL_REQUEST",
  SURE_TO_RECALL_THIS_APPROVAL_REQUEST: "SURE_TO_RECALL_THIS_APPROVAL_REQUEST",
  SURE_TO_CANCEL_THIS_APPROVAL_REQUEST: "SURE_TO_CANCEL_THIS_APPROVAL_REQUEST",
  SURE_TO_ROLLBACK_THIS_APPROVAL_REQUEST:
    "SURE_TO_ROLLBACK_THIS_APPROVAL_REQUEST",
  SURE_TO_RECALL: "SURE_TO_RECALL",
  ATTACHMENT_STILL_UPLOADING: "ATTACHMENT_STILL_UPLOADING",
  CLAIM_STILL_UNDER_PROCESSING: "CLAIM_STILL_UNDER_PROCESSING",
  CLAIM_REJECTED: "CLAIM_REJECTED",
  AUTHORIZE_PROVIDER_CONFIRMATION: "AUTHORIZE_PROVIDER_CONFIRMATION",
  PREMIUM_DATA_UP_UNTIL: "PREMIUM_DATA_UP_UNTIL",
  FOLLOWING_SERVICES_NOT_RELINKED: "FOLLOWING_SERVICES_NOT_RELINKED",
  NO_INFORMATION_TO_DISPLAY: "NO_INFORMATION_TO_DISPLAY",
  NO_ROWS_HAVE_BEEN_ACCEPTED: "NO_ROWS_HAVE_BEEN_ACCEPTED",
  PROVIDER_SERVICE_MAPPING_WORKSPACE_FINAL_MESSAGE:
    "PROVIDER_SERVICE_MAPPING_WORKSPACE_FINAL_MESSAGE",
  CONVERSION_FROM_PACK_SIZE_UNIT_TO_DOSE_UNIT_DOES_NOT_EXIST:
    "CONVERSION_FROM_PACK_SIZE_UNIT_TO_DOSE_UNIT_DOES_NOT_EXIST",
  CHOOSE_AT_LEAST_ONE_OPTION: "CHOOSE_AT_LEAST_ONE_OPTION",
  SYNC_PLAN_CONFIGURATION_NOTIFICATION: "SYNC_PLAN_CONFIGURATION_NOTIFICATION",
  SERVICE_DETAIL_NOT_LINKED_TO_ANY_PARTIAL_EXCLUSION:
    "SERVICE_DETAIL_NOT_LINKED_TO_ANY_PARTIAL_EXCLUSION",
  SERVICE_NOT_LINKED_TO_ANY_PARTIAL_EXCLUSION:
    "SERVICE_NOT_LINKED_TO_ANY_PARTIAL_EXCLUSION",
  WORKSPACE_FINAL_MESSAGE: "WORKSPACE_FINAL_MESSAGE",
  WORKSPACE_CAN_IGNORE_DUPLICATES_SELECTION_MESSAGE:
    "WORKSPACE_CAN_IGNORE_DUPLICATES_SELECTION_MESSAGE",
  WORKSPACE_PREVENT_MULTIPLE_DUPLICATES_SELECTION_MESSAGE:
    "WORKSPACE_PREVENT_MULTIPLE_DUPLICATES_SELECTION_MESSAGE",
  WORKSPACE_NOT_ALL_DUPLICATES_WERE_RESOLVED:
    "WORKSPACE_NOT_ALL_DUPLICATES_WERE_RESOLVED",
  WORKSPACE_CANNOT_IGNORE_DUPLICATES_SELECTION_MESSAGE:
    "WORKSPACE_CANNOT_IGNORE_DUPLICATES_SELECTION_MESSAGE",
  WORKSPACE_NOT_ALL_VALUES_WERE_MAPPED: "WORKSPACE_NOT_ALL_VALUES_WERE_MAPPED",
  SHOULD_MAP_ALL_VALUES: "SHOULD_MAP_ALL_VALUES",
  NO_VALUES_FOR_MAPPING: "NO_VALUES_FOR_MAPPING",
  NO_CLAIMS_FOUND: "NO_CLAIMS_FOUND",
  SYNC_MOBILE_NUMBER: "SYNC_MOBILE_NUMBER",
  WORKSPACE_NO_MATCHES_SELECTED_MESSAGE:
    "WORKSPACE_NO_MATCHES_SELECTED_MESSAGE",
  WORKSPACE_NOT_ALL_MATCHES_WERE_MAPPED:
    "WORKSPACE_NOT_ALL_MATCHES_WERE_MAPPED",
  WORKSPACE_ACCEPT_ALL_MATCHES_MESSAGE: "WORKSPACE_ACCEPT_ALL_MATCHES_MESSAGE",
  APPLICATION_WORKSPACE_ADDITION_WORKSPACE_FINAL_MESSAGE:
    "APPLICATION_WORKSPACE_ADDITION_WORKSPACE_FINAL_MESSAGE",
  APPLICATION_WORKSPACE_FINAL_MESSAGE: "APPLICATION_WORKSPACE_FINAL_MESSAGE",
  NO_APPROVED_INSURED_TO_GENERATE: "NO_APPROVED_INSURED_TO_GENERATE",
  APPLICATION_IS_RELATED_TO_UNFINISHED_TASK:
    "APPLICATION_IS_RELATED_TO_UNFINISHED_TASK",
  DIFFERENT_FROM_PLAN: "DIFFERENT_FROM_PLAN",
  POLICY_RELATED_TO_UNFINISHED_TASK: "POLICY_RELATED_TO_UNFINISHED_TASK",
  POLICYHOLDER_IS_NOT_A_GROUP: "POLICYHOLDER_IS_NOT_A_GROUP",
  INSURED_CANCELLATION_WORKSPACE_FINAL_MESSAGE:
    "INSURED_CANCELLATION_WORKSPACE_FINAL_MESSAGE",
  INSURED_TRANSFER_WORKSPACE_FINAL_MESSAGE:
    "INSURED_TRANSFER_WORKSPACE_FINAL_MESSAGE",
  SPECIAL_DISCOUNT_WORKSPACE_FINAL_MESSAGE:
    "SPECIAL_DISCOUNT_WORKSPACE_FINAL_MESSAGE",
  POLICY_RELATED_TO_CLAIMS_EXCEEDING_SELECTED_CANCELLATION_DATE:
    "POLICY_RELATED_TO_CLAIMS_EXCEEDING_SELECTED_CANCELLATION_DATE",
  CARD_ISSUANCE_WORKSPACE_FINAL_MESSAGE:
    "CARD_ISSUANCE_WORKSPACE_FINAL_MESSAGE",
  RESYNC_INSTALLMENTS_MESSAGE: "RESYNC_INSTALLMENTS_MESSAGE",
  RESYNC_ADMIN_COST_INSTALLMENTS_MESSAGE:
    "RESYNC_ADMIN_COST_INSTALLMENTS_MESSAGE",
  RESYNC_BROKER_INSTALLMENTS_MESSAGE: "RESYNC_BROKER_INSTALLMENTS_MESSAGE",
  SURE_TO_IGNORE_PENDING_PREAUTHORIZATION_APPROVALS:
    "SURE_TO_IGNORE_PENDING_PREAUTHORIZATION_APPROVALS",
  IMPORTED_SERVICE_LOST: "IMPORTED_SERVICE_LOST",
  REMOVE_RELATED_ATTACHMENT: "REMOVE_RELATED_ATTACHMENT",
  NO_ATTACHMENT_PROVIDED: "NO_ATTACHMENT_PROVIDED",
  ARE_YOU_SURE_TO_CONTINUE: "ARE_YOU_SURE_TO_CONTINUE",
  NO_AVAILABLE_IMAGES_TO_DOWNLOAD: "NO_AVAILABLE_IMAGES_TO_DOWNLOAD",
  DELETE_CLAIM_FORM: "DELETE_CLAIM_FORM",
  NO_ORGANIZATION_BANK_ACCOUNT_ACTION_HISTORY_FOUND:
    "NO_ORGANIZATION_BANK_ACCOUNT_ACTION_HISTORY_FOUND",
  NO_ORGANIZATION_HISTORY_FOUND: "NO_ORGANIZATION_HISTORY_FOUND",
  NO_BANK_ACCOUNT_HISTORY_FOUND: "NO_BANK_ACCOUNT_HISTORY_FOUND",
  NO_ACTIVATION_HISTORY_FOUND: "NO_ACTIVATION_HISTORY_FOUND",
  NO_PERSON_HISTORY_FOUND: "NO_PERSON_HISTORY_FOUND",
  NO_BANK_ACCOUNT_ACTIONS_HISTORY_FOUND:
    "NO_BANK_ACCOUNT_ACTIONS_HISTORY_FOUND",
  DELETED_SUCCESSFULLY: "DELETED_SUCCESSFULLY",
  ADDED_SUCCESSFULLY: "ADDED_SUCCESSFULLY",
  NO_CARDS_FOUND: "NO_CARDS_FOUND",
  NO_DEPENDENTS_FOUND: "NO_DEPENDENTS_FOUND",
  PROVIDER_NO_MATCHES_SELECTED_MESSAGE: "PROVIDER_NO_MATCHES_SELECTED_MESSAGE",
  SHOULD_OVERRIDE_PROVIDER_DATA: "SHOULD_OVERRIDE_PROVIDER_DATA",
  INSURED_IS_ELIGIBLE: "INSURED_IS_ELIGIBLE",
  IMPORTED_SERVICE_DISCOUNT_LOST: "IMPORTED_SERVICE_DISCOUNT_LOST",
  IMPORTED_OPERATION_CATEGORY_LOST: "IMPORTED_OPERATION_CATEGORY_LOST",
  NO_VENDOR_BANK_ACCOUNT_ACTION_HISTORY_FOUND:
    "NO_VENDOR_BANK_ACCOUNT_ACTION_HISTORY_FOUND",
  RESET_PROVIDER_CONFIRMATION: "RESET_PROVIDER_CONFIRMATION",
  PROVIDER_MESSAGE_HAS_NO_PROVIDERS: "PROVIDER_MESSAGE_HAS_NO_PROVIDERS",
  SYNCED_SUCCESSFULLY: "SYNCED_SUCCESSFULLY",
  POSTED_SUCCESSFULLY: "POSTED_SUCCESSFULLY",
  UN_POSTED_SUCCESSFULLY: "UN_POSTED_SUCCESSFULLY",
  INSURED_MESSAGE_HAS_NO_INSUREDS: "INSURED_MESSAGE_HAS_NO_INSUREDS",
  THERE_IS_NO_INVOICE: "THERE_IS_NO_INVOICE",
  THERE_IS_NO_BILL: "THERE_IS_NO_BILL",
  SERVICE_TABLE_TYPE_IS_REQUIRED: "SERVICE_TABLE_TYPE_IS_REQUIRED",
  DATE_IS_REQUIRED: "DATE_IS_REQUIRED",
  PROVIDER_HAS_NO_ACTIVE_CONTRACT: "PROVIDER_HAS_NO_ACTIVE_CONTRACT",
  SEND_PROVIDER_ACTIVATION_SMS: "SEND_PROVIDER_ACTIVATION_SMS",
  PERSON_WORKSPACE_FINAL_MESSAGE: "PERSON_WORKSPACE_FINAL_MESSAGE",
  BILL_PAYMENT_PROCESSING_WORKSPACE_FINAL_MESSAGE:
    "BILL_PAYMENT_PROCESSING_WORKSPACE_FINAL_MESSAGE",
  WORKSPACE_ACCEPT_ALL_PERSON_DRAFT_MATCHES_MESSAGE:
    "WORKSPACE_ACCEPT_ALL_PERSON_DRAFT_MATCHES_MESSAGE",
  THIS_ACTION_IS_IRREVERSIBLE_SURE_TO_CONTINUE:
    "THIS_ACTION_IS_IRREVERSIBLE_SURE_TO_CONTINUE",
  MAILBOX_OUTDATED_CLICK_TO_REFRESH: "MAILBOX_OUTDATED_CLICK_TO_REFRESH",
  NOTIFICATIONS_OUTDATED_CLICK_TO_REFRESH:
    "NOTIFICATIONS_OUTDATED_CLICK_TO_REFRESH",
  SURE_TO_INCLUDE_CONSULTATION: "SURE_TO_INCLUDE_CONSULTATION",
  PVP_IS_RELATED_TO_UNFINISHED_TASK: "PVP_IS_RELATED_TO_UNFINISHED_TASK",
  NO_AVAILABLE_SCANNED_CLAIMS_ATTACHMENTS:
    "NO_AVAILABLE_SCANNED_CLAIMS_ATTACHMENTS",
  FAILED_TO_DOWNLOAD_ATTACHMENT: "FAILED_TO_DOWNLOAD_ATTACHMENT",
  SURE_TO_DELETE_THIS_REQUEST: "SURE_TO_DELETE_THIS_REQUEST",
  NO_PVI_TO_EXPORT_MESSAGE: "NO_PVI_TO_EXPORT_MESSAGE",
  PVI_GROUP_HAS_NO_INVOICES: "PVI_GROUP_HAS_NO_INVOICES",
  ALL_FIELDS_WILL_BE_UPDATED: "ALL_FIELDS_WILL_BE_UPDATED",
  SURE_TO_DELETE_MEDICAL_CASE: "SURE_TO_DELETE_MEDICAL_CASE",
  DELETE_PLAN_EXCLUSION_MESSAGE: "DELETE_PLAN_EXCLUSION_MESSAGE",
  UNLINK_PLAN_EXCLUSION_WARNING: "UNLINK_PLAN_EXCLUSION_WARNING",
  ADHERENT_COUNT_AT_INCEPTION_WARNING: "ADHERENT_COUNT_AT_INCEPTION_WARNING",
  SERVICE_WORKSPACE_FINAL_MESSAGE: "SERVICE_WORKSPACE_FINAL_MESSAGE",
  SERVICE_PRICING_WORKSPACE_FINAL_MESSAGE:
    "SERVICE_PRICING_WORKSPACE_FINAL_MESSAGE",
  SERVICE_DISCOUNT_WORKSPACE_FINAL_MESSAGE:
    "SERVICE_DISCOUNT_WORKSPACE_FINAL_MESSAGE",
  SERVICE_OPERATION_CATEGORY_WORKSPACE_FINAL_MESSAGE:
    "SERVICE_OPERATION_CATEGORY_WORKSPACE_FINAL_MESSAGE",
  WORKSPACE_ACCEPT_ALL_DRAFT_MATCHES_MESSAGE:
    "WORKSPACE_ACCEPT_ALL_DRAFT_MATCHES_MESSAGE",
  LOSS_RATIO_DATES_WARNING: "LOSS_RATIO_DATES_WARNING",
});
