export default Object.freeze({
  // for check-in and check-out please use the one from iconsFa
  CHECK_IN: "k-icon k-font-icon k-i-launch",
  CHECKOUT: "k-icon k-font-icon k-i-launch k-flip-h k-flip-v",
  DELETE: "k-icon k-font-icon k-i-trash",
  PRINT: "k-icon k-font-icon k-i-print",
  REFRESH: "k-icon k-font-icon k-i-refresh",
  RECALL: "k-icon k-font-icon k-i-reset",
  SUBMIT: "k-icon k-font-icon k-i-check", //upload
  FOLLOW_UP: "k-icon k-font-icon k-i-reload",
  SEARCH: "k-icon k-font-icon k-i-search",
  DOWNLOAD: "k-icon k-font-icon k-i-download",
  WORK_SPACE: "k-icon k-font-icon k-i-table-insert",
  ADD: "k-icon k-font-icon k-i-plus",
  ARROW_CHEVRON_DOWN: "k-icon k-font-icon k-i-arrow-chevron-down",
  ARROW_CHEVRON_RIGHT: "k-icon k-font-icon k-i-arrow-chevron-right",
  ARROW_CHEVRON_LEFT: "k-icon k-font-icon k-i-arrow-chevron-left",
  COMMENT: "k-icon k-font-icon k-i-comment",
  EDIT: "k-icon k-font-icon k-i-edit",
  REPORT: "k-icon k-font-icon k-i-paste-plain-text",
  REJECT: "k-icon k-font-icon k-i-minus-outline",
  ATTACH: "k-icon k-font-icon k-i-attachment",
  CLOSE: "k-icon k-font-icon k-i-close k-i-x",
  USER: "k-icon k-font-icon k-i-user",
  UN_SET: "k-icon k-font-icon k-i-minus-circle",
  ACCEPT: "k-icon k-font-icon k-i-track-changes-accept",
  CALCULATE: "k-icon k-font-icon k-i-calculator",
  PASTE: "k-icon k-font-icon k-i-paste-plain-text",
  RELOAD: "k-icon k-font-icon k-i-reload",
  CURRENCY: "k-icon k-font-icon k-i-currency",
  CHECKBOX_CHECKED: "k-icon k-font-icon k-i-checkbox-checked",
  CANCEL: "k-icon k-font-icon k-i-cancel",
  CHECKMARK: "k-icon k-font-icon k-i-check",
  REPLACE: "k-icon k-font-icon k-i-arrows-swap",
  ARROW_UP: "k-icon k-font-icon k-i-arrow-up",
  ARROW_DOWN: "k-icon k-font-icon k-i-arrow-down",
  DASH: "k-icon k-font-icon k-i-minus",
  OPEN: "k-icon k-font-icon k-i-folder-open",
  MORE: "k-icon k-font-icon k-i-more-horizontal",
  PERCENTAGE: "k-icon k-font-icon k-i-percent",
  COVERAGE: "k-icon k-font-icon k-i-dribbble",
  ARROW_60_RIGHT: "l-icon k-i-arrow-60-right",
  CIRCLE: ".k-icon k-font-icon k-i-circle",
  INFO: "k-icon k-font-icon k-i-info",
  GENERATE: "k-icon k-font-icon k-i-gears",
  LOCK: "k-icon k-font-icon k-i-lock",
  UNLOCK: "k-icon k-font-icon k-i-unlock",
  VIEW: "k-icon k-font-icon k-i-eye",
  TABLE_CLEAR: "k-icon k-font-icon k-i-table-delete",
  PLAY: "k-icon k-font-icon k-i-play",
  RESOLVE: "k-icon k-font-icon k-i-check-circle",
  EMAIL: "k-icon k-font-icon k-i-email k-i-envelop k-i-letter",
  SHOPPING_CART: "k-icon k-font-icon k-i-cart k-i-shopping-cart",
  CONFIGURATION: "k-icon k-font-icon k-i-gear",
  MORE_HORIZONTAL: "k-icon k-font-icon k-i-more-horizontal",
  RADIO_BUTTON_CHECKED: "k-icon k-font-icon k-i-radiobutton-checked",
  RADIO_BUTTON: "k-icon k-font-icon k-i-radiobutton",
  CHECKBOX: "k-icon k-font-icon k-i-checkbox",
  ZOOM: "k-icon k-font-icon k-i-zoom k-i-search",
  COPY: "k-icon k-font-icon k-i-copy",
  FILE_ASCX: "k-icon k-font-icon k-i-file-ascx",
  WARNING: "k-icon k-font-icon k-i-warning k-i-exception k-i-error",
  CHECK_OUTLINE: "k-icon k-font-icon k-i-check-outline k-i-checkmark-outline",
  LINK: "k-icon k-font-icon k-i-link-horizontal",
  IMPORT_IMAGES: "k-icon k-font-icon k-i-image-insert",
  SYNC: "k-icon k-font-icon k-i-rotate",
  MAIN: "k-icon k-font-icon k-i-track-changes",
  EXPORT: "k-icon k-font-icon k-i-file-excel",
  HYPERLINK_OPEN_SMALL: "k-icon k-font-icon k-i-hyperlink-open-sm",
  CALENDAR: "k-icon k-font-icon k-i-calendar",
  NOTIFICATION: "k-icon k-font-icon k-i-notification",
  MAP_MERKER_TARGET: "k-icon k-font-icon k-i-map-marker-target",
  CHECKMARK_CIRCLE: "k-icon k-i-check-outline k-i-checkmark-outline",
});
