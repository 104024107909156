import { useMemo } from "react";
import {
  GridColumn,
  GridColumnProps,
  GridCellProps,
} from "@progress/kendo-react-grid";
import AppDropDownCell from "../components/grid/AppDropDownCell";
import AppBooleanCell from "../components/grid/AppBooleanCell";
import AppAutoCompleteCell from "../components/grid/AppAutoCompleteCell";
import AppGridNumericTextBox from "../components/grid/AppNumericTextBoxCell";
import AppGridTextBoxCell from "../components/grid/AppGridTextBoxCell";
import AppTwoTextBoxesCell from "../components/grid/AppTwoTextBoxesCell";
import AppImageCell from "../components/grid/AppImageCell";
import AppGridHyperlinkCell from "../components/grid/AppGridHyperlinkCell";
import AppButtonCell from "../components/grid/AppButtonCell";
import {
  AppGridColumnProps,
  AppGridColumnPropsWithType,
} from "../ui/AppGridColumnProps";
import uiHelper from "./uiHelper";
import AppDatePickerCell from "../components/grid/AppDatePickerCell";
import { colorCategory } from "../base/colorCategory";
import AppProgressCell from "../components/grid/AppProgressCell";
import AppIndicatorCell from "../components/grid/AppIndicatorCell";
import AppDropDownMenuCell from "../components/grid/AppDropDownMenuCell";

export interface NotificationCell {
  field: string;
  title: string;
  notificationType: colorCategory; //"success" | "error" | "warning" | "disabled";
  notificationPopupTextField?: string;
}

const getColumn = (
  { columnType, handleOnRowClick, ...rest }: AppGridColumnPropsWithType,
  index: number
) => {
  const key = "column-" + index;
  switch (columnType) {
    case "text":
      return GetTextGridColumn({ columnKey: key, ...rest });
    case "boolean":
      return GetBooleanGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "date":
      return getDateGridColumn({ columnKey: key, ...rest });
    case "dropdown":
      return GetDropdownGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "numeric":
      return getNumericGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "autocomplete":
      return GetAutoCompleteGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "customnumeric":
      return GetCustomNumericGridColumn({
        columnKey: key,
        ...rest,
      });
    case "customtext":
      return GetCustomTextGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "customtwotext":
      return GetCustomTwoTextBoxesGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "image":
      return GetImageGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "hyperlink":
      return GetHyperlinkGridColumn({ columnKey: key, ...rest });
    case "commandButton":
      return GetCommandButtonGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "customdate":
      return GetCustomDateGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "progress":
      return GetProgressGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "indicator":
      return GetIndicatorGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    case "dropdownMenu":
      return GetDropDownMenuGridColumn({
        columnKey: key,
        handleOnRowClick,
        ...rest,
      });
    // case "tooltip-text":
    //   return GetTextWithTooltipGridColumn({
    //     columnKey: key,
    //     handleOnRowClick: handleOnRowClick,
    //     ...rest,
    //   });
  }
};

const CustomHeader = (props: any) => {
  return (
    <div className="k-link" onClick={props.onClick}>
      <span title={props.title}>{props.title}</span>
      {props.children}
    </div>
  );
};

const GetTextGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  showTooltip = true,
  columnKey,
  defaultOnNoValue,
  getCustomCellStyle,
  ...rest
}: AppGridColumnProps) => {
  const CustomTextWithTooltipCell = useMemo(
    () => (props: any) => {
      if (props.rowType === "groupHeader") {
        return null;
      } else
        return (
          <td
            title={
              showTooltip
                ? uiHelper.getObjectProperty(props.dataItem, field)
                : undefined
            }
            style={getCustomCellStyle ? getCustomCellStyle(props.dataItem) : {}}
          >
            {uiHelper.getObjectProperty(props.dataItem, field)
              ? uiHelper.getObjectProperty(props.dataItem, field)
              : defaultOnNoValue}
          </td>
        );
    },
    [field, showTooltip]
  );

  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      // editor="text"
      key={columnKey}
      headerCell={CustomHeader}
      cell={CustomTextWithTooltipCell}
      {...rest}
    />
  );
};

const GetCustomTextGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  editableInNew = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  onCloseEdit,
  handleOnRowClick,
  inlineEdit = editable,
  onCellClick,
  notificationFieldList = [],
  getImage,
  columnKey,
  showContentTooltip = true,
  extraClasses,
  translatable,
  ...rest
}: AppGridColumnProps) => {
  const CustomAppGridTextBoxCell = useMemo(
    () => (props: any) => {
      if (props.rowType === "groupHeader") {
        return null;
      } else {
        return (
          <AppGridTextBoxCell
            editable={editable}
            editableInNew={editableInNew}
            onCloseEdit={onCloseEdit}
            handleOnRowClick={handleOnRowClick}
            inlineEdit={inlineEdit}
            onCellClick={onCellClick}
            notificationFieldList={notificationFieldList}
            getImage={getImage}
            showContentTooltip={showContentTooltip}
            extraClasses={extraClasses}
            translatable={translatable}
            {...props}
          />
        );
      }
    },
    [
      editable,
      editableInNew,
      getImage,
      handleOnRowClick,
      inlineEdit,
      notificationFieldList,
      onCellClick,
      onCloseEdit,
      showContentTooltip,
    ]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      key={columnKey}
      cell={CustomAppGridTextBoxCell}
      // headerCell={CustomHeader}
      // editor="text"
      {...rest}
    />
  );
};

const getNumericGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  columnKey,
  ...rest
}: AppGridColumnProps) => {
  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      editor="numeric"
      filter="numeric"
      key={columnKey}
      headerCell={CustomHeader}
      {...rest}
    />
  );
};

const GetCustomNumericGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  editableInNew = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  onCloseEdit,
  min,
  max,
  handleOnRowClick,
  columnKey,
  disabled = false,
  currency,
  fixedCurrency,
  numericTextBoxFormat,
  ...rest
}: AppGridColumnProps) => {
  const CustomAppGridNumericTextBox = useMemo(
    () => (props: any) => {
      if (props.rowType === "groupHeader") {
        return null;
      }
      if (props.rowType === "groupFooter") return <td></td>;
      // rendring footer cell correctly 'ChronicFormServiceBalancesGrid'
      else
        return (
          <AppGridNumericTextBox
            editable={editable}
            editableInNew={editableInNew}
            {...props}
            // onClick={onClick}
            onCloseEdit={onCloseEdit}
            handleOnRowClick={handleOnRowClick}
            min={min}
            max={max}
            disabled={disabled}
            currency={currency}
            fixedCurrency={fixedCurrency}
            numericTextBoxFormat={numericTextBoxFormat}
          />
        );
    },
    [disabled, editable, editableInNew, handleOnRowClick, max, min, onCloseEdit]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      key={columnKey}
      cell={CustomAppGridNumericTextBox}
      headerCell={CustomHeader}
      {...rest}
    />
  );
};

const GetBooleanGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  editableInNewMode = false,
  editableInEditMode = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  onCheckStateChanged,
  onCheckStateChanging,
  handleOnRowClick,
  columnKey,
  ...rest
}: AppGridColumnProps) => {
  const CustomAppBooleanCell = useMemo(
    () => (props: any) => (
      <AppBooleanCell
        {...props}
        field={field}
        editable={editable}
        editableInNewMode={editableInNewMode}
        editableInEditMode={editableInEditMode}
        onCheckStateChanging={onCheckStateChanging}
        onCheckStateChanged={onCheckStateChanged}
        handleOnRowClick={handleOnRowClick}
      />
    ),
    [
      editable,
      editableInEditMode,
      editableInNewMode,
      field,
      handleOnRowClick,
      onCheckStateChanged,
      onCheckStateChanging,
    ]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      key={columnKey}
      cell={CustomAppBooleanCell}
      headerCell={CustomHeader}
      editor="boolean"
      filter="boolean"
      {...rest}
    />
  );
};

const getDateGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  columnKey,
  ...rest
}: AppGridColumnProps) => {
  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      filter="date"
      editor="date"
      format="{0:d}"
      key={columnKey}
      headerCell={CustomHeader}
      {...rest}
    />
  );
};

const GetDropdownGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  editableInNew = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  required = false,
  textField,
  dropDownListData,
  bindingObject,
  objectBinding = false,
  addDummyItem = false,
  defaultItem = null,
  onCloseEdit,
  lazyLoadData,
  handleOnRowClick,
  readOnlyDisplayProperty,
  columnKey,
  ...rest
}: AppGridColumnProps) => {
  const CustomAppDropDownCell = useMemo(
    () => (props: any) => (
      <AppDropDownCell
        {...props}
        dropDownListData={dropDownListData || []}
        editable={editable}
        editableInNew={editableInNew}
        required={required}
        textField={textField || ""}
        bindingObject={bindingObject}
        objectBinding={objectBinding}
        addDummyItem={addDummyItem}
        onCloseEdit={onCloseEdit}
        lazyLoadData={lazyLoadData}
        handleOnRowClick={handleOnRowClick}
        defaultItem={defaultItem}
        readOnlyDisplayProperty={readOnlyDisplayProperty}
      />
    ),
    [
      dropDownListData,
      addDummyItem,
      bindingObject,
      defaultItem,
      editable,
      editableInNew,
      handleOnRowClick,
      lazyLoadData,
      objectBinding,
      onCloseEdit,
      readOnlyDisplayProperty,
      required,
      textField,
    ]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      key={columnKey}
      cell={CustomAppDropDownCell}
      headerCell={CustomHeader}
      {...rest}
    />
  );
};

const GetAutoCompleteGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  editableInNew = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  loadData,
  autocompleteObjectName = "",
  textField,
  bindingObject = "",
  onChange,
  onCloseEdit,
  handleOnRowClick,
  handleOnClose,
  handleOnKeyDown,
  notificationFieldList = [],
  columnKey,
  submitOnChange,
  onClick,
  extraClasses,
  autoCompleteSearchField,
  getImage,
  handleImageClick,
  handleItemClick,
  autocompleteObjects,
  showLocalNameOnly,
  showForeignNameOnly,
  customRowColor,
  ...rest
}: AppGridColumnProps) => {
  let loadNewData: (prop: any) => Promise<any> | null;
  if (loadData) loadNewData = loadData;
  else
    loadNewData = () => {
      return null;
    };
  // if (field === "ServiceModel" && textField !== "SpecializedCode")

  const CustomAppAutoCompleteCell = useMemo(
    () => (props: any) => (
      <AppAutoCompleteCell
        {...props}
        //     {...props}
        locked={locked}
        bindingObject={bindingObject}
        autocompleteObjectName={autocompleteObjectName}
        loadData={loadNewData}
        textField={textField}
        editable={editable}
        editableInNew={editableInNew}
        handleOnRowClick={handleOnRowClick}
        onClose={handleOnClose}
        notificationFieldList={notificationFieldList}
        handleOnKeyDown={handleOnKeyDown}
        submitOnChange={submitOnChange}
        onClick={onClick}
        autoCompleteSearchField={autoCompleteSearchField}
        getImage={getImage}
        handleImageClick={handleImageClick}
        handleItemClick={handleItemClick}
        autocompleteObjects={autocompleteObjects}
        showLocalNameOnly={showLocalNameOnly}
        showForeignNameOnly={showForeignNameOnly}
        customRowColor={customRowColor}
      />
    ),
    [
      autocompleteObjectName,
      bindingObject,
      editable,
      editableInNew,
      handleOnClose,
      handleOnKeyDown,
      handleOnRowClick,
      loadNewData,
      locked,
      notificationFieldList,
      submitOnChange,
      textField,
      showLocalNameOnly,
      showForeignNameOnly,
    ]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      key={columnKey}
      cell={CustomAppAutoCompleteCell}
      headerCell={CustomHeader}
      {...rest}
    />
  );
};

const GetCustomTwoTextBoxesGridColumn = ({
  fieldList,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  editableInNew = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  onChange,
  onCloseEdit,
  handleOnRowClick,
  separator,
  unitLabel,
  disabled = false,
  columnKey,
  customCellStatus,
  dependencyField,
  renderCellStatusBasedOnDependencyField,
  ...rest
}: AppGridColumnProps) => {
  const CustomAppTwoTextBoxesCell = useMemo(
    () => (props: any) => (
      <AppTwoTextBoxesCell
        {...props}
        editable={editable}
        fieldList={fieldList || []}
        separator={separator || "/"}
        unitLabel={unitLabel}
        handleOnRowClick={handleOnRowClick}
        onCloseEdit={onCloseEdit}
        disabled={disabled}
        customCellStatus={customCellStatus}
        dependencyField={dependencyField}
        renderCellStatusBasedOnDependencyField={
          renderCellStatusBasedOnDependencyField
        }
      />
    ),
    [
      customCellStatus,
      dependencyField,
      disabled,
      editable,
      fieldList,
      handleOnRowClick,
      onCloseEdit,
      renderCellStatusBasedOnDependencyField,
      separator,
      unitLabel,
    ]
  );
  return (
    <GridColumn
      title={title}
      // field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      key={columnKey}
      cell={CustomAppTwoTextBoxesCell}
      headerCell={CustomHeader}
      {...rest}
    />
  );
};

const getCommandColumn = ({
  width = 70,
  minResizableWidth,
  cell,
  locked = true,
}: GridColumnProps) => {
  return (
    <GridColumn
      locked={locked}
      title=""
      field=""
      width={width}
      resizable={false}
      minResizableWidth={minResizableWidth}
      editable={false}
      sortable={false}
      filterable={false}
      groupable={false}
      cell={cell}
    />
  );
};

const GetImageGridColumn = ({
  width = "auto",
  title,
  minResizableWidth,
  field,
  imageSource,
  handleOnRowClick,
  locked = false,
  tooltipTitle,
  tooltipField,
  resizable = false,
  getImage,
  columnKey,
  editable = false,
  onUploadImage,
  onDeleteImage,
  onLoadImage,
  imageIcon,
  sortable = false,
  extraClasses,
  imageInfoField,
}: AppGridColumnProps) => {
  const CustomAppImageCell = useMemo(
    () => (props: any) => (
      <AppImageCell
        {...props}
        field={field}
        handleOnRowClick={handleOnRowClick}
        imageSource={imageSource}
        tooltipTitle={tooltipTitle}
        tooltipField={tooltipField}
        getImage={getImage}
        editable={editable}
        onUploadImage={onUploadImage}
        onDeleteImage={onDeleteImage}
        onLoadImage={onLoadImage}
        imageIcon={imageIcon}
        extraClasses={extraClasses}
        imageInfoField={imageInfoField}
      />
    ),
    [
      editable,
      field,
      getImage,
      handleOnRowClick,
      imageSource,
      // The following dependenices are skipped from Image Viewer issue
      // onDeleteImage,
      // onLoadImage,
      // onUploadImage,
      tooltipField,
      tooltipTitle,
      imageIcon,
      imageInfoField,
    ]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      locked={locked}
      width={width}
      resizable={resizable}
      minResizableWidth={minResizableWidth}
      editable={false}
      sortable={sortable}
      filterable={false}
      groupable={false}
      key={columnKey}
      cell={CustomAppImageCell}
      headerCell={CustomHeader}
    />
  );
};

const GetCommandButtonGridColumn = ({
  width = "auto",
  title,
  minResizableWidth,
  field,
  getImage,
  handleOnRowClick,
  locked = false,
  imageUrl,
  icon,
  iconClass,
  onClick,
  customCellStatus,
  tooltipTitle,
  tooltipField,
  columnKey,
  buttonWidth,
  buttonClasses,
}: AppGridColumnProps) => {
  const CustomAppButtonCell = useMemo(
    () => (props: any) => (
      <AppButtonCell
        {...props}
        field={field}
        handleOnRowClick={handleOnRowClick}
        getImage={getImage}
        imageUrl={imageUrl}
        icon={icon}
        iconClass={iconClass}
        onClick={onClick}
        customCellStatus={customCellStatus}
        tooltipTitle={tooltipTitle}
        tooltipField={tooltipField}
        buttonWidth={buttonWidth}
        buttonClasses={buttonClasses}
      />
    ),
    [
      buttonClasses,
      buttonWidth,
      customCellStatus,
      field,
      getImage,
      handleOnRowClick,
      icon,
      iconClass,
      imageUrl,
      onClick,
      tooltipField,
      tooltipTitle,
    ]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      locked={locked}
      width={width}
      resizable={false}
      minResizableWidth={minResizableWidth}
      editable={false}
      sortable={false}
      filterable={false}
      groupable={false}
      key={columnKey}
      cell={CustomAppButtonCell}
      headerCell={CustomHeader}
    />
  );
};

const GetHyperlinkGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  required = false,
  textField,
  dropDownListData,
  bindingObject,
  objectBinding = false,
  addDummyItem = false,
  getImage,
  onCloseEdit,
  lazyLoadData,
  handleOnRowClick,
  navigatePath = "",
  columnKey,
  navigateField,
  disableNavigation,
  disableNavigationTextType,
  getNavigationPath,
  onClick,
  notificationFieldList,
  ignoreUrlParameters,
  ...rest
}: AppGridColumnProps) => {
  const CustomAppGridHyperlinkCell = useMemo(
    () => (props: any) => (
      <AppGridHyperlinkCell
        {...props}
        field={field}
        getImage={getImage}
        navigatePath={navigatePath}
        navigateField={navigateField}
        disableNavigation={disableNavigation}
        disableNavigationTextType={disableNavigationTextType}
        getNavigationPath={getNavigationPath}
        notificationFieldList={notificationFieldList}
        ignoreUrlParameters={ignoreUrlParameters}
        onClick={onClick}
      />
    ),
    [field, navigatePath]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      key={columnKey}
      cell={CustomAppGridHyperlinkCell}
      headerCell={CustomHeader}
      {...rest}
    />
  );
};

const GetCustomDateGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  editableInNew = false,
  sortable = false,
  filterable = false,
  groupable = false,
  locked = false,
  // onValueChanged,
  onCloseEdit,
  handleOnRowClick,
  onCellClick,

  columnKey,
  showContentTooltip = true,
  ...rest
}: AppGridColumnProps) => {
  const CustomAppDatePickerCell = useMemo(
    () => (props: any) => (
      <AppDatePickerCell
        editable={editable}
        editableInNew={editableInNew}
        handleOnRowClick={handleOnRowClick}
        onCellClick={onCellClick}
        showContentTooltip={showContentTooltip}
        // onValueChanged={onValueChanged}
        onCloseEdit={onCloseEdit}
        {...props}
      />
    ),
    [
      editable,
      editableInNew,
      handleOnRowClick,
      onCellClick,
      // onValueChanged,
      showContentTooltip,
    ]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      locked={locked}
      key={columnKey}
      cell={CustomAppDatePickerCell}
      headerCell={CustomHeader}
      {...rest}
    />
  );
};

const GetProgressGridColumn = ({
  width = "auto",
  title,
  minResizableWidth,
  field,
  handleOnRowClick,
  locked = false,
  tooltipTitle,
  tooltipField,
  resizable = false,
  columnKey,
  editable = false,
  showContentTooltip = true,
  renderBarColor,
}: AppGridColumnProps) => {
  const CustomAppProgressCell = useMemo(
    () => (props: any) => (
      <AppProgressCell
        {...props}
        field={field}
        handleOnRowClick={handleOnRowClick}
        tooltipTitle={tooltipTitle}
        tooltipField={tooltipField}
        editable={editable}
        showContentTooltip={showContentTooltip}
        renderBarColor={renderBarColor}
      />
    ),
    [editable, field, handleOnRowClick, tooltipField, tooltipTitle]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      locked={locked}
      width={width}
      resizable={resizable}
      minResizableWidth={minResizableWidth}
      editable={false}
      sortable={false}
      filterable={false}
      groupable={false}
      key={columnKey}
      cell={CustomAppProgressCell}
      headerCell={CustomHeader}
    />
  );
};

const GetIndicatorGridColumn = ({
  field,
  title,
  width = "auto",
  minResizableWidth = 30,
  editable = false,
  sortable = false,
  filterable = false,
  groupable = false,
  columnKey,
  handleOnRowClick,
  indicator,
  ...rest
}: AppGridColumnProps) => {
  const CustomAppIndicatorCell = useMemo(
    () => (props: any) => (
      <AppIndicatorCell
        {...props}
        field={field}
        editable={false}
        indicatorFieldName={indicator?.indicatorFieldName}
        successIndicator={{
          iconColor: indicator?.successIndicator?.iconColor,
          icon: indicator?.successIndicator?.icon,
          indicatorFieldValue: indicator?.successIndicator?.indicatorFieldValue,
          indicatorText: indicator?.successIndicator?.indicatorText,
        }}
        errorIndicator={{
          iconColor: indicator?.errorIndicator?.iconColor,
          icon: indicator?.errorIndicator?.icon,
          indicatorFieldValue: indicator?.errorIndicator?.indicatorFieldValue,
          indicatorText: indicator?.errorIndicator?.indicatorText,
        }}
        handleOnRowClick={handleOnRowClick}
        {...rest}
      />
    ),
    [field, indicator?.indicatorFieldName]
  );
  return (
    <GridColumn
      title={title}
      field={field}
      width={width}
      minResizableWidth={minResizableWidth}
      editable={editable}
      sortable={sortable}
      filterable={filterable}
      groupable={groupable}
      key={columnKey}
      cell={CustomAppIndicatorCell}
      {...rest}
    />
  );
};

const GetDropDownMenuGridColumn = ({
  width = "auto",
  title,
  minResizableWidth,
  field,
  getImage,
  handleOnRowClick,
  locked = false,
  onClick,
  tooltipTitle,
  tooltipField,
  columnKey,
  menu,
  hidden,
}: AppGridColumnProps) => {
  const CustomAppDropDownMenuCell = (props: any) => {
    if (props.rowType === "groupHeader") {
      return null;
    }
    if (props.rowType === "groupFooter") return <td></td>;
    else
      return (
        <AppDropDownMenuCell
          {...props}
          field={field}
          handleOnRowClick={handleOnRowClick}
          onClick={onClick}
          tooltipTitle={tooltipTitle}
          tooltipField={tooltipField}
          menu={menu}
          hidden={hidden}
        />
      );
  };
  return (
    <GridColumn
      title={title}
      field={field}
      locked={locked}
      width={width}
      resizable={false}
      minResizableWidth={minResizableWidth}
      editable={false}
      sortable={false}
      filterable={false}
      groupable={false}
      key={columnKey}
      cell={CustomAppDropDownMenuCell}
      headerCell={CustomHeader}
    />
  );
};

const appGridColumnsHelper = {
  getColumn,
  GetTextGridColumn,
  GetBooleanGridColumn,
  getDateGridColumn,
  GetDropdownGridColumn,
  getCommandColumn,
  getNumericGridColumn,
  GetAutoCompleteGridColumn,
  GetCustomNumericGridColumn,
  GetCustomTextGridColumn,
  GetCustomTwoTextBoxesGridColumn,
  GetImageGridColumn,
  GetHyperlinkGridColumn,
  GetCommandButtonGridColumn,
  GetCustomDateGridColumn,
  GetProgressGridColumn,
  GetIndicatorGridColumn,
};

export default appGridColumnsHelper;
