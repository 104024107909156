/** @format */

import { lazy, Suspense, useEffect, useState } from "react";
import "./styles/theme.scss";
import "bootstrap/scss/bootstrap.scss";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./styles/main.scss";
import settingsStorage from "./common/storage/settingsStorage";
import { useTranslation } from "react-i18next";
import routes from "./base/routes";
import ELanguageCodes from "./common/base/languageCodes";
import authStorage from "./common/storage/authStorage";
import ProtectedRoute from "./common/routes/ProtectedRoute";
import ProtectedLogin from "./common/routes/ProtectedLogin";
import LocalizationProvider from "./common/context/localizationContext";
import portals from "./base/portals";
import AuthenticationProvider from "./common/context/authenticationContext";
import uiHelper from "./common/helpers/uiHelper";
import baseRoutes from "./common/base/baseRoutes";
import UserPortalProvider from "./common/context/userPortalContext";
import usersApi from "./api/users";
import userHelper from "./base/userHelper";
import ApplicationContextProvider from "./common/context/applicationContext";
import AppCustomSearchField, {
  customComponentIncludesSearchField,
} from "./components/search/AppCustomSearchField";
import autocompleteObjects from "./base/autocompleteObjects";
import definitions from "./api/definitions";
import searchFields from "./api/searchFields";
import SuspenseComponent from "./pages/general/SuspenseComponent";
import iconFactory from "./helpers/faIconsFactory";
import CreateProviderWebRequestPage from "./pages/providers/CreateProviderWebRequest";
import SearchMedicalNetworkPage from "./pages/providers/SearchMedicalNetworkPage";
import MailboxContextProvider from "./common/context/MailboxContext";
import workflowObjectsApi from "./api/workflowObjects";
import NotificationContextProvider from "./common/context/NotificationContext";
import notificationsApi from "./api/notifications";
import UseNotificationContextProvider from "./common/context/useNotificationContext";
import useNotifications from "./notifications/useNotifications";
import BaseUserModel from "./common/interfaces/BaseUserModel";
import UserCurrentStatusModel from "./common/interfaces/UserCurrentStatusModel";
import CryptoJS from "crypto-js";
import notificationTypeCodes from "./interfaces/notifications/notificationTypeCodes";
import workflowStorage from "./common/storage/workflowStorage";
import systemSettingsCodes from "./enums/systemSettings/systemSettingsCodes";
import systemSettingApi from "./api/systemSettings";

function App() {
  const [locale, setLocale] = useState("en");
  const [isRTL, setIsRTL] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<BaseUserModel | null>(null);
  const [password, setPassword] = useState<string>("");
  const [token, setToken] = useState<string>("");
  const [defaultCountryID, setDefaultCountryID] = useState<number>();
  const [userCurrentStatus, setUserCurrentStatus] =
    useState<UserCurrentStatusModel | null>();
  const [defaultSystemDecimalRounding, setDefaultSystemDecimalRounding] =
    useState(2);
  const { i18n } = useTranslation();

  const onStorageUpdate = (e: StorageEvent) => {
    const { newValue, key } = e;

    if (key === process.env.REACT_APP_NAME + "UCS") {
      if (newValue) {
        const decryptedUserCurrentStatus = CryptoJS.AES.decrypt(
          newValue,
          authStorage.secret
        );
        if (!decryptedUserCurrentStatus) return null;
        const descryptedString = decryptedUserCurrentStatus.toString(
          CryptoJS.enc.Utf8
        );

        if (!descryptedString) return null;

        let userCurrentStatus = null;
        userCurrentStatus = JSON.parse(descryptedString);
        setUserCurrentStatus(userCurrentStatus);
      } else {
        setUserCurrentStatus(null);
      }
    }
  };

  useEffect(() => {
    loadSettings();
    checkUser();
    document.title = "ETPA";
    window.addEventListener("storage", onStorageUpdate);
    return () => {
      window.removeEventListener("storage", onStorageUpdate);
    };
    // eslint-disable-next-line
  }, []);

  const checkUser = () => {
    const storedUser = authStorage.getUserInfo();
    const storedToken = authStorage.getToken();
    const storedPassword = authStorage.getUserPassword();
    const defaultCountryID = authStorage.getDefaultCountryID();
    const userCurrentStatus = authStorage.getUserCurrentStatus();

    if (storedUser !== null && storedToken !== undefined) {
      setUser(storedUser);
      setToken(storedToken);
    } else {
      authStorage.removeToken();
      authStorage.removeUserInfo();
      setUser(null);
      setToken("");
    }

    if (storedPassword !== undefined) setPassword(storedPassword);
    else {
      authStorage.removeUserPassword();
      setPassword("");
    }

    if (userCurrentStatus) setUserCurrentStatus(userCurrentStatus);
    else {
      authStorage.removeUserCurrentStatus();
      setUserCurrentStatus(null);
    }

    if (defaultCountryID)
      setDefaultCountryID(Number.parseInt(defaultCountryID));
    else {
      authStorage.removeDefaultCountryID();
      setDefaultCountryID(undefined);
    }

    setLoading(false);
  };

  const loadSettings = async () => {
    let language = await settingsStorage.getLanguage(); //or from user settings

    if (!language) {
      language = navigator.language;
      if (language && language.startsWith(ELanguageCodes.ARABIC.Name))
        language = ELanguageCodes.ARABIC.Code;
      else language = ELanguageCodes.ENGLISH.Code;
    }
    if (language) {
      setLocale(language);
      switch (language) {
        case ELanguageCodes.ARABIC.Code:
          setIsRTL(true);
          break;
        default:
          setIsRTL(false);
          break;
      }
    }
    document.body.dir = i18n.dir(isRTL ? "rtl" : "ltr");
    i18n.changeLanguage(language);

    const response = await systemSettingApi.getSystemSettingValue(
      systemSettingsCodes.DEFAULT_SYSTEM_DECIMAL_ROUNDING
    );
    if (response.ok) {
      setDefaultSystemDecimalRounding(response.data);
    } else setDefaultSystemDecimalRounding(2);
  };

  const getNotificationType = (notificationTypeCode: string) => {
    switch (notificationTypeCode) {
      case notificationTypeCodes.APPROVAL_ACCEPTED:
      case notificationTypeCodes.APPROVAL_AUTHORIZED:
      case notificationTypeCodes.CHRONIC_FORM_ACCEPTED:
      case notificationTypeCodes.TICKET_RESOLVED:
      case notificationTypeCodes.REIMBURSEMENT_REQUEST_VALIDATED:
      case notificationTypeCodes.PROVIDER_SUBMISSION_GENERATED:
        return "success";
      case notificationTypeCodes.APPROVAL_REJECTED:
      case notificationTypeCodes.REIMBURSEMENT_REQUEST_REJECTED:
      case notificationTypeCodes.APPROVAL_REQUEST_REJECTED:
      case notificationTypeCodes.CHRONIC_FORM_REQUEST_REJECTED:
        return "error";
      case notificationTypeCodes.APPROVAL_RETURNED:
      case notificationTypeCodes.REIMBURSEMENT_REQUEST_RETURNED_TO_SENDER:
      case notificationTypeCodes.APPROVAL_REQUEST_RETURNED_TO_SENDER:
      case notificationTypeCodes.CHRONIC_FORM_REQUEST_RETURNED_TO_SENDER:
        return "info";
      default:
        return "success";
    }
  };

  const isNotificationTypeCountable = (notificationTypeCode: string) => {
    switch (notificationTypeCode) {
      case notificationTypeCodes.APPROVAL_RECEIVED:
      case notificationTypeCodes.APPROVAL_REQUEST_RECEIVED:
      case notificationTypeCodes.TICKET_RECEIVED:
      case notificationTypeCodes.REIMBURSEMENT_REQUEST_RECEIVED:
      case notificationTypeCodes.PROVIDER_WEB_REQUEST_RECEIVED:
      case notificationTypeCodes.APPOINTMENT_RECEIVED:
      case notificationTypeCodes.TRANSMITTAL_RECEIVED:
      case notificationTypeCodes.BATCH_RECEIVED:
      case notificationTypeCodes.CHRONIC_FORM_RECEIVED:
      case notificationTypeCodes.USER_TASK_RECEIVED:
        return true;
      default:
        return false;
    }
  };

  const InsuranceCompanyPortal = lazy(
    () => import("./portals/insuranceCompany/InsuranceCompanyPortal")
  );
  const ErrorPage = lazy(() => import("./pages/general/ErrorPage"));
  const AdminPortal = lazy(() => import("./portals/admin/AdminPortal"));
  const ProviderPortal = lazy(
    () => import("./portals/provider/ProviderPortal")
  );
  const ClientPortal = lazy(() => import("./portals/client/ClientPortal"));
  const ReinsurerPortal = lazy(
    () => import("./portals/reinsurer/ReinsurerPortal")
  );
  const Activation = lazy(() => import("./pages/authentication/Activation"));
  const LogIn = lazy(() => import("./pages/authentication/LogIn"));
  const ProviderApprovalQueryPage = lazy(
    () => import("./pages/general/ProviderApprovalQueryPage")
  );

  const logOut = () => {
    setToken("");
    setUser(null);
    setUserCurrentStatus(null);
    setPassword("");
    authStorage.removeToken();
    authStorage.removeUserInfo();
    authStorage.removeUserPassword();
    setDefaultCountryID(undefined);
    authStorage.removeDefaultCountryID();
    authStorage.removeUserCurrentStatus();
    uiHelper.setDocumentTitle(undefined);
    workflowStorage.removeMailboxItemsCount();
    workflowStorage.removeNotificationsCount();
  };

  return !loading ? (
    <div dir={isRTL ? "rtl" : ""} className={isRTL ? "k-rtl" : ""}>
      <LocalizationProvider
        locale={locale}
        isRTL={isRTL}
        setLocale={setLocale}
        setIsRTL={setIsRTL}
        updateUserLanguage={usersApi.updateUserLanguage}
        translationAdditionalProps={{
          companyName:
            process.env["REACT_APP_COMPANY_NAME_" + locale.toLocaleUpperCase()],
        }}
      >
        <AuthenticationProvider
          user={user}
          setUser={setUser}
          token={token}
          setToken={setToken}
          password={password}
          setPassword={setPassword}
          logOut={logOut}
          defaultCountryID={defaultCountryID}
          setDefaultCountryID={setDefaultCountryID}
          userCurrentStatus={userCurrentStatus}
          setUserCurrentStatus={setUserCurrentStatus}
        >
          <UseNotificationContextProvider
            useNotifications={useNotifications}
            getNotificationType={getNotificationType}
            isNotificationTypeCountable={isNotificationTypeCountable}
          >
            <UserPortalProvider
              userHasAccessToCommand={usersApi.userHasAccessToCommand}
              getUserPortal={userHelper.getUserPortal}
            >
              <ApplicationContextProvider
                CustomSearchField={AppCustomSearchField}
                customComponentIncludesSearchField={
                  customComponentIncludesSearchField
                }
                autocompleteObjects={autocompleteObjects}
                definitions={definitions}
                getSearchFields={searchFields.getSearchFields}
                iconFactory={iconFactory}
                defaultSystemDecimalRounding={defaultSystemDecimalRounding}
              >
                <NotificationContextProvider
                  getNotificationsCount={
                    notificationsApi.getUserNotificationsCount
                  }
                >
                  <MailboxContextProvider
                    getMailboxItemsTotalCount={
                      workflowObjectsApi.getMailboxItemsTotalCount
                    }
                  >
                    <BrowserRouter
                      basename={process.env.REACT_APP_ROUTER_BASENAME}
                    >
                      <Routes>
                        {/* <ClientPortal /> */}
                        {/* <AdminPortal /> */}
                        {/* <Activation /> */}
                        {/* <LogIn /> */}
                        {/* <MainDrawerContainer menuPath="/menu/clientMenu.json">
       <MenuClientRoutes />
     </MainDrawerContainer> */}
                        {/* <ProtectedLogin
              path={routes.LOGIN}
              component={LogIn}
              //   auth={auth}
            /> */}
                        <Route
                          path={baseRoutes.LOGIN}
                          element={
                            <ProtectedLogin
                              path={baseRoutes.LOGIN}
                              component={LogIn}
                              //   auth={auth}
                            />
                          }
                        />
                        <Route
                          path={routes.ACTIVATE_ACCOUNT}
                          element={
                            <Suspense fallback={<SuspenseComponent />}>
                              <Activation />
                            </Suspense>
                          }
                        />
                        <Route
                          path={routes.SEARCH_MEDICAL_NETWORK}
                          element={
                            <Suspense fallback={<SuspenseComponent />}>
                              <SearchMedicalNetworkPage />
                            </Suspense>
                          }
                        />
                        <Route
                          path={routes.PROVIDER_WEB_REQUEST}
                          element={
                            <Suspense fallback={<SuspenseComponent />}>
                              <CreateProviderWebRequestPage />
                            </Suspense>
                          }
                        />
                        <Route
                          path={portals.INSURANCE_COMPANY_PORTAL + "/*"}
                          element={
                            <ProtectedRoute
                              // path={portals.ADMIN_PORTAL}
                              component={InsuranceCompanyPortal}
                              //   auth={auth}
                            />
                          }
                        />
                        <Route
                          path={portals.CLIENT_PORTAL + "/*"}
                          element={
                            <ProtectedRoute
                              // path={portals.ADMIN_PORTAL}
                              component={ClientPortal}
                              //   auth={auth}
                            />
                          }
                        />
                        <Route
                          path={portals.PROVIDER_PORTAL + "/*"}
                          element={
                            <ProtectedRoute component={ProviderPortal} />
                          }
                        />
                        <Route
                          path={portals.REINSURER_PORTAL + "/*"}
                          element={
                            <ProtectedRoute component={ReinsurerPortal} />
                          }
                        />
                        <Route
                          path={portals.ADMIN_PORTAL + "/*"}
                          element={
                            <ProtectedRoute
                              // path={portals.ADMIN_PORTAL}
                              component={AdminPortal}
                              //   auth={auth}
                            />
                          }
                        />
                        <Route
                          path={baseRoutes.HOME}
                          element={<Navigate to={baseRoutes.LOGIN} />}
                        />
                        <Route
                          path={baseRoutes.ERROR_PAGE}
                          element={
                            <Suspense fallback={<SuspenseComponent />}>
                              <ErrorPage />
                            </Suspense>
                          }
                        />
                        <Route
                          path={routes.PROVIDER_APPROVAL_QUERY_PAGE}
                          element={
                            <Suspense fallback={<SuspenseComponent />}>
                              <ProviderApprovalQueryPage />
                            </Suspense>
                          }
                        />
                        {/* <Route
              path={routes.MENU}
              element={
                <ProtectedRoute //path={routes.MENU}
                  component={MenuTest}
                />
              }
            /> */}

                        {/* <Route
              path={routes.LOGIN}
              element={(props: any) => {
                return user ? <LogIn {...props} /> : <Navigate to="/admin" />;
              }}
            /> */}
                      </Routes>
                    </BrowserRouter>
                  </MailboxContextProvider>
                </NotificationContextProvider>
              </ApplicationContextProvider>
            </UserPortalProvider>
          </UseNotificationContextProvider>
        </AuthenticationProvider>
      </LocalizationProvider>
    </div>
  ) : (
    <div>Loading...</div>
  );
}

export default App;
