import apiClient from "../common/api/client";
import PagingModel from "../common/interfaces/PagingModel";
import BaseResponse from "../common/interfaces/api/BaseResponse";
import AdvancePaymentModel from "../interfaces/advancePayments/AdvancePaymentModel";
import endpoints from "./endpoints";

const endpoint = endpoints.ADVANCE_PAYMENTS;

const act_download_advance_payment_details_report =
  "/download-advance-payment-details-report";
const act_download_advance_payments_audit_report =
  "/download-advance-payments-audit-report";

const getAdvancePayments = (
  criteria: Record<string, any>,
  paging: PagingModel
): BaseResponse<AdvancePaymentModel[]> => {
  return apiClient.post(
    endpoint,
    JSON.stringify({
      ...paging,
      ...criteria,
    }),
    {
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadAdvancePaymentDetailsReport = (criteria: Record<string, any>) => {
  return apiClient.post(
    endpoint + act_download_advance_payment_details_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      // "Content-Type": "text/html",
      // Accept: "*/*",
      // "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const downloadAdvancePaymentsAuditReport = (criteria: Record<string, any>) => {
  return apiClient.post(
    endpoint + act_download_advance_payments_audit_report,
    JSON.stringify({
      ...criteria,
    }),
    {
      // "Content-Type": "text/html",
      // Accept: "*/*",
      // "Accept-Encoding": "gzip, deflate, br",
      responseType: "arraybuffer",
      headers: {
        "X-HTTP-Method-Override": "GET",
      },
    }
  );
};

const advancePaymentsApi = {
  getAdvancePayments,
  downloadAdvancePaymentDetailsReport,
  downloadAdvancePaymentsAuditReport,
};

export default advancePaymentsApi;
