import { useMediaQuery } from "react-responsive";
import { Breakpoints } from "../constants/breakpoints";
import breakpointKeys from "../constants/breakpointKeys";

export default function useBreakpoint() {
  const isDesktop = useMediaQuery({ minWidth: Breakpoints.desktop });
  const isHighRes = useMediaQuery({ minWidth: Breakpoints.highres });
  const isTablet = useMediaQuery({ minWidth: Breakpoints.tablet });
  const isMobile = useMediaQuery({ minWidth: Breakpoints.mobile });

  const getBreakpoint = () => {
    if (isHighRes) return breakpointKeys.HIGH_RES;
    if (isDesktop) return breakpointKeys.DESKTOP;
    if (isTablet) return breakpointKeys.TABLET;
    if (isMobile) return breakpointKeys.MOBILE;
    return breakpointKeys.DESKTOP;
  };
  const breakpoint = getBreakpoint();

  return {
    breakpoint,
  };
}
