export default Object.freeze({
  ACTIVATE_ACCOUNT: "/ActivateAccount",
  SEARCH_PROVIDERS: "/Providers",
  BROWSE: "/browse",
  PROFILE: "/Profile",
  // MANAGE_APPROVAL: "/ManageApproval",
  PENDING_APPROVALS: "/PendingApprovals",
  MENU: "/menu",
  ONLINE_APPROVALS: "/OnlineApprovals",
  APPROVALS: "/Approvals",
  REQUESTS: "/Requests",
  SEARCH_REQUESTS: "/SearchRquests",
  PENDING_REQUESTS: "/PendingRequests",
  LATEST_INVOICES: "/LatestInvoices",
  CHRONIC_FORMS: "/ChronicForms",
  ISSUANCE: "/Issuance",
  BILLING: "/Billing",
  REPORTS: "/Reports",
  TRACK_APPROVALS: "/TrackApprovals",
  APPROVAL_REQUEST: "/ApprovalRequest",
  NEW_REQUEST: "/NewRequest",
  MAIN_REQUEST: "/MainRequest",
  REIMBURSEMENT_BATCH: "/ReimbursementBatch",
  REIMBURSEMENT_BATCHES: "/ReimbursementBatches",
  REIMBURSEMENT_REQUEST: "/ReimbursementRequest",
  TICKETS: "/Tickets",
  PENDING_TICKETS: "/PendingTickets",
  TRACKED: "/Tracked",
  PRESCRIPTION: "/Prescription",
  TRACK_PRESCRIPTIONS: "/TrackPrescriptions",
  PROVIDER_TRANSMITTAL: "/ProviderTransmittal",
  POLICIES: "/Policies",
  PAYMENTS: "/Payments",
  TRACK_ONLINE_SUBMISSIONS: "/TrackOnlineSubmissions",
  TRACK_TRANSMITTALS: "/TrackTransmittals",
  TRACK_BILLS: "/TrackBills",
  BILLS: "/Bills",
  PVPS: "/PVPs",
  MEDICAL_FILES: "/MedicalFiles",
  UPDATE_INFORMATION: "/UpdateInformation",
  BENEFICIARIES: "/Beneficiaries",
  SUBMISSIONS: "/Submissions",
  REVISIONS: "/Revisions",
  REIMBURSEMENT_ITEM: "/ReimbursementItem",
  TRANSMITTAL: "/Transmittal",
  TEST1: "/Test1",
  TEST2: "/Test2",
  MEDICAL_NETWORK: "/MedicalNetwork",
  PROVIDERS: "/Providers",
  MANAGE_PROVIDERS: "/ProvidersManage",
  CLAIM_FORMS: "/Claims",
  PVI_GROUPS: "/ICMPaymentVoucherGroups",
  PVIS: "/ICMPaymentVouchers",
  BATCHES: "/Batches",
  UNDERWRITING: "/Underwriting",
  PLANS: "/Plans",
  PENDING_TRANSMITTALS: "/PendingTransmittals",
  PROVIDER_GROUPS: "/ProviderGroups",
  PENDING_BATCHES: "/PendingBatches",
  MANAGE_TRANSMITTALS: "/TransmittalsManage",
  MANAGE_BATCHES: "/BatchesManage",
  SERVICES: "/Services",
  SERVICES_HIERARCHY: "/ServicesHierarchy",
  SERVICES_CHAPTERS: "/ServicesChapters",
  SERVICES_CATEGORIES: "/ServicesCategories",
  SERVICE_TYPES: "/ServiceTypes",
  SERVICE_TABLE_TYPES: "/ServiceTableTypes",
  OPERATION_CATEGORIES: "/OperationCategories",
  UNIT_TYPES: "/UnitTypes",
  DRUG_MANUFACTURER: "/DrugManufacturer",
  APPLICATIONS: "/Applications",
  CUSTOMER_SERVICE: "/CustomerService",
  PACKS: "/Packs",
  ACCOUNTING: "/Accounting",
  PVP_GROUPS: "/ProviderPaymentVoucherGroups",
  CDV_GROUPS: "/ClientDebitVoucherGroups",
  DIAGNOSIS: "/Diagnosis",
  DIAGNOSIS_HIERARCHY: "/DiagnosisHierarchy",
  MANAGE_DIAGNOSES: "/DiagnosesManage",
  DIAGNOSIS_CATEGORIES: "/DiagnosisCategories",
  DIAGNOSIS_BLOCKS: "/DiagnosisBlocks",
  DIAGNOSIS_CHAPTERS: "/DiagnosisChapters",
  MEDICAL_CASES: "/MedicalCases",
  PVC_GROUPS: "/ClientPaymentVoucherGroups",
  MANAGE_PVI_GROUPS: "/PVIGroupsManage",
  PENDING_CLIENT_PAYMENTS: "/PendingClientPayments",
  EXPORT_MEDICAL_CASES: "/ExportMedicalCases",
  MANAGE_SERVICES: "/ServicesManage",
  REQUEST_TYPE: "/RequestType",
  REQUEST_RESOLUTION: "/RequestResolution",
  MANAGE_PVP_GROUPS: "/PVPGroupsManage",
  MANAGE_PVC_GROUPS: "/PVCGroupsManage",
  PVCS: "/ClientPaymentVouchers",
  MANAGE_CDV_GROUPS: "/CDVGroupsManage",
  CDVS: "/ClientDebitVouchers",
  REQUEST_MEDIUM: "/RequestMedium",
  REQUEST_CATEGORY: "/RequestCategory",
  REQUEST_ASSIGNMENT_RULE: "/RequestAssignmentRule",
  SERVICE_PRICE_UPDATE_REQUEST: "/ServicePriceUpdateRequest",
  SERVICE_WORKSPACE: "/ServiceWorkspace",
  INVOICES: "/Invoices",
  ISSUANCE_ITEMS: "/IssuanceItems",
  PENDING_ISSUANCE_ITEMS: "/PendingIssuanceItems",
  PRICING_WORKSPACE: "/PricingWorkspace",
  BILL_PAYMENTS: "/BillPayments",
  PARTIES: "/Parties",
  ORGANIZATIONS: "/Organizations",
  CLAIM_FORM_TYPES: "/ClaimFormTypes",
  CLAIM_TYPES: "/ClaimTypes",
  MANAGE: "/Manage",
  CLAIMS: "/Claims",
  CLAIM_TYPE: "/ClaimType",
  BOOKLET_TYPE: "/BookletTypes",
  CLAIM_BOOKLET: "/ClaimBooklets",
  SYNDICATE_SHARE: "/SyndicateShares",
  BATCH_ASSIGNMENT_RULE: "/BatchAssignmentRules",
  MANAGE_REIMBURSEMENT: "/ReimbursementsManage",
  REIMBURSEMENT_REQUESTS: "/ReimbursementRequests",
  PERSON: "/Person",
  SYNDICATES: "/Syndicates",
  CONTACTS: "/Contacts",
  PROFESSIONS: "/Professions",
  INDUSTRIES: "/Industries",
  POLICYHOLDERS: "/Policyholders",
  GROUP: "/Group",
  INDIVIDUAL: "/Individual",
  PROVIDER_TYPES: "/ProviderTypes",
  PROVIDER_CLASSIFICATIONS: "/ProviderClassifications",
  PROVIDER_SPECIALTIES: "/ProviderSpecialties",
  PROVIDER_CATEGORIES: "/PorivderCategories",
  GROUP_CLASSIFICATIONS: "/ProviderGroupClassifications",
  OFFERED_SERVICES: "/OfferedServices",
  PROVIDER_REQUEST_ASSIGNMENT_RULES: "/ProviderRequestAssignmentRules",
  DEFAULT_PROVIDER_CONTRACT_SETTINGS: "/DefaultProviderContractSettings",
  REMOVE_DUPLICATES: "/RemoveDuplicateProviders",
  SCHEMES: "/Schemes",
  PRICING_SCHEMES: "/PricingSchemes",
  DELETED_APPROVALS: "/DeletedApprovals",
  TPA_COMPANIES: "/TPACompanies",
  TPA_CONTRACTS: "/TPAContracts",
  REBATE_SCHEMES: "/RebateSchemes",
  DISCOUNT_SCHEMES: "/DiscountSchemes",
  APPOINTMENTS: "/Appointments",
  SERVICE_GROUPS: "/ServiceGroup",
  EXPORT_SERVICE_GROUPS: "/ExportServiceGroups",
  WEB_REQUESTS: "/WebRequests",
  ONLINE_REQUESTS: "/OnlineRequests",
  INSURANCE_COMPANIES: "/InsuranceCompanies",
  BROKERS: "/Brokers",
  OPERATION_SCHEMES: "/OperationSchemes",
  PENDING_PROVIDER_PAYMENTS: "/PendingProviderPayments",
  PENDING_REIMBURSEMENT_PAYMENTS: "/PendingReimbursementPayments",
  PENDING_BROKER_PAYMENTS: "/PendingBrokerPayments",
  MANAGE_BANKS: "/BanksManage",
  BANKING_ACCOUNT_TYPES: "/BankingAccountTypes",
  BANK_ACCOUNTS: "/BankAccounts",
  BANKS: "/Banks",
  FINANCE: "/Finance",
  CUSTOMERS: "/Customers",
  VENDORS: "/Vendors",
  USERS: "/Users",
  MODULES: "/Modules",
  ACTIONS: "/Actions",
  COMMANDS: "/Commands",
  ROLES: "/Roles",
  INSURANCE_PRODUCTS: "/InsuranceProducts",
  EMPLOYEES: "/Employees",
  GROUPS: "/Groups",
  COMMUNICATIONS: "/Communications",
  PROVIDER_COMMUNICATIONS: "/ProviderCommunications",
  INSURED_COMMUNICATIONS: "/InsuredCommunications",
  PROVIDER_MESSAGE: "/ProviderMessage",
  TAXES: "/Taxes",
  CHARGES: "/Charges",
  CARD_TYPES: "/CardTypes",
  INSURED_MESSAGE: "/InsuredMessage",
  COVERAGE_PLANS: "/CoveragePlans",
  ENDORSEMENT_TYPES: "/EndorsementTypes",
  INGREDIENTS: "/Ingredients",
  CHEMICAL_COMPOUNDS: "/ChemicalCompounds",
  REINSURERS: "/Reinsurers",
  RELATION_OFFICER_TYPES: "/RelationOfficerTypes",
  RELATION_OFFICERS: "/RelationOfficers",
  COLLECTION_AGENTS: "/CollectionAgents",
  PREMIUM_FUNDERS: "/PremiumFunders",
  INSURANCE_COMPANY_BENEFITS: "/InsuranceCompanyBenefits",
  BENEFIT_MAPPING_TEMPLATES: "/BenefitMappingTemplates",
  FEATURES: "/Features",
  POLICYHOLDER_TYPES: "/PolicyholderTypes",
  INSURED_RELATIONS: "/InsuredRelations",
  CLASS_TYPES: "/ClassTypes",
  PLAN_TYPES: "/PlanTypes",
  INSURANCE_TYPES: "/InsuranceTypes",
  POLICY_CLASSIFICATIONS: "/PolicyClassifications",
  ACCOUNT_CATEGORIES: "/AccountCategories",
  ACCOUNTS: "/Accounts",
  COST_CENTERS: "/CostCenters",
  FISCAL_YEARS: "/FiscalYears",
  ACCOUNTING_TRANSACTIONS: "/AccountingTransactions",
  MANAGE_APPROVALS: "/ApprovalsManage",
  APPROVAL_ENGINE: "/ApprovalEngine",
  WORKSPCAES: "/Workspaces",
  MANAGE_CUSTOMER_SERVICE: "/CustomerServiceManage",
  SERVICE_INDICATORS: "/ServiceIndicator",
  DIAGNOSIS_INDICATORS: "/DiagnosisIndicator",
  MOBILE_NUMBER_WORKSPACES: "/MobileNumberWorkspaces",
  SEND_SMS: "/SendSMS",
  SERVICE_REPORT: "/ServiceReport",
  UTILITIES: "/Utilities",
  GEOGRAPHY: "/Geography",
  COUNTRIES: "/Countries",
  CITIES: "/Cities",
  LOCATIONS: "/Locations",
  GOVERNORATES: "Governorates",
  GEOGRAPHICAL_SCOPES: "/GeographicalScopes",
  GENERAL: "/General",
  FILE_REVIEWS: "/FileReviews",
  MEDICAL_CONDITIONS: "/MedicalConditions",
  FORM_MEDICAL_CONDITIONS: "/FormMedicalConditions",
  REGIONS: "/Regions",
  TREASURIES: "/Treasuries",
  SEARCH_MEDICAL_NETWORK: "/SearchMedicalNetwork",
  PROVIDER_WEB_REQUEST: "/ProviderWebRequest",
  ASSIGNMENT_RULES: "/AssignmentRules",
  VIEW_ATTACHMENT: "/ViewAttachment",
  ACCOUNT_TYPES: "/AccountTypes",
  ACCOUTNING_ITEMS: "/AccountingItems",
  TRANSACTIONS: "/Transactions",
  PRICE_UPDATE_REQUESTS: "/PriceUpdateRequests",
  APPROVAL_REQUEST_ASSIGNMENT_RULES: "/ApprovalRequestAssignmentRules",
  CHRONIC_FORM_REQUESTS: "/ChronicFormRequests",
  PROVIDER_APPROVAL_QUERY_PAGE: "/ProviderApprovalQueryPage",
  QUERY_PAGE: "/QueryPage",
  DEPARTMENTS: "/departments",
  DEPARTMENT_TEAMS: "/departmentTeams",
  CHRONIC: "/Chronic",
  RENEWAL: "/Renewal",
  MANAGE_CHRONIC: "/ChronicManage",
  CHRONIC_FORM_REQUEST_ASSIGNMENT_RULES: "/ChronicFormRequestAssignmentRules",
  COMMENTS: "/Comments",
  COMMENT_TYPES: "/CommentTypes",
  DOCUMENTS: "/Documents",
  DOCUMENT_GROUPS: "/DocumentGroups",
  MESSAGE_TEMPLATES: "/MessageTemplates",
});
